.carousel {
    display: inline-flex;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.carousel-label {
    font-family: 'Work Sans', sans-serif;
}

.drop-the-shadow {
    text-shadow: 2px 2px black;
}